import React from 'react'
import '../css/home.css';
import philosophy from '../images/philosophy.jpg';
import modesty from '../images/modesty.jpg';
import teachers from '../images/teachers.jpg';
import team from '../images/team_2024.JPG';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
        
        <div className="home-content">  
            <Helmet>
                <title>In Motion Dance | Home</title>
            </Helmet>
                
                <div id='welcomeMsg'>
                    <h1 id="pageTitle"> Welcome to InMotion Dance!</h1>
                </div>
                <div className="welcomeImg">
                        <img src={team} alt='team_picture'/>
                </div>
                {/* This are the three 'zig-zag' boxes. Philosophy, Modesty, and Teachers. */}
            <div class="home-container">
                <div class="home-row">
                    <div class="home-column-66">
                        <h2>Philosophy</h2>
                        <p>One of our main goals is to foster a fun dance environment that is warm and supportive. Our instructors 
                            teach a full range of classes for ages 18 months to adult. We also offer several adult fitness and dance
                             classes.<br/><br/>

                        Most dancers attend classes once a week to share in movement and their love of dance. For any dancer who has
                         higher aspirations in mind, we have three competing teams.
                         <ul>
                         <br/>
                             <li> Emerge Dance Company (ages 7 to 11)</li>
                             <li> Eclipse Dance Company (Skill Level)</li>
                             <li> Elite Dance Company (Skill Level up to age 18)</li>  
                        </ul><br/><br/>

                        We have dancers on teams at Vista Heights, Frontier, Westlake (Drill, Alliance, Dance, and Ballroom), Cedar Valley
                         (Drill, Cheer and Dance) and Snow College. Dancers have also participated at BYU, UVU, Snow College and SALT 
                         choreography workshop and competition (1st place) in Salt Lake City.</p>
                    </div>
                    <div class="home-column-33">
                        <img src={philosophy} alt='philosophy_image'/>
                    </div>
                </div>
            </div>
            <div class="home-container">
                <div class="home-row">
                <div class="home-column-33">
                        <img src={modesty} alt='modesty_image'/>
                    </div>
                    <div class="home-column-66">
                    <h2>Modesty</h2>
                        <p>We carefully select music, costumes and choreography that are appropriate for a family community.</p>
                    </div>
                </div>
            </div>
            <div class="home-container">
                <div class="home-row">
                    <div class="home-column-66">
                    <h2>Teachers</h2>
                        <p>Our amazing instructors have danced professionally, studied dance in college or performed at the college level
                             and have many years experience in teaching dancers of all ages.  Several have degrees and all of our
                              teen teachers assisted in classes before teaching their own class.  We attend conferences, workshops, educational
                               classes and reach out to others in our community for continued learning.</p>
                    </div>
                    <div class="home-column-33">
                        <img src={teachers} alt='teachers_image'/>
                    </div>
                </div>
            </div>
                {/* This is Events and Closures section (side by side in large view, and stack in mobile) */}
            <div class="card-row">
                <div class="card-column">
                    <div class="card">
                        <h2>Events</h2>
                        <ul>
                             
                            {/* <li>We are still accepting registrations.  Please check our “rates” page for your monthly
                                 tuition payment.  Our non-refundable annual registration fee is a once a year charge which is due upon registration, tuition is a
    separate charge from our annual registration fee.  Tuition is charged on the first day of each month.</li> */}
                            <li>Sat December 7th, 2024 - Winter Showcase @ Vista Heights Middle School</li>

                        </ul>
                    </div>
                </div>
                <div class="card-column">
                    <div class="card">
                        <h2>Closures</h2>
                        <h3><center>(Subject to change)</center></h3>
                        <ul>
                            <li>Mon December 23rd, 2024 thru Sat January 4th, 2025 - Christmas Break</li>
                            <li>Mon February 17th, 2025 - President's Day</li>
                            <li>Sat March 29th, 2025 thru Sat April 5th, 2025 - Spring Break</li>
                            <li>Mon May 26th, 2025 - Memorial Day</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}
